<template>
  <div class="about-wrap">
    <div class="head-wrap">
      <div
        :style="{ backgroundImage: 'url(' + backgoundImg + ')' }"
        class="background-wrap"
      ></div>
      <div class="mask"></div>
      <el-card shadow="always" class="com-info">
        <p class="intro-title mb20">公司简介</p>
        <p class="intro">
          可美公司是一家坐落在江苏常熟有着30多年发展历史的生产型企业。近年来，可美公司加大研发投入，相继开发了一次性平面口罩、KN95等防疫物资。同时，在工艺上大胆创新，先后研发出了包括喷印口罩、烫印口罩、满版定位口罩等一系列深受客户喜爱的产品。公司拥有3万方厂房、自动化生产流水线，符合ISO9001:2015质量管理体系要求，产品均通过国标及国际检测。
        </p>

        <div class="video-wrap mt20">
          <el-carousel
            :interval="4000"
            :type="isMobile ? 'normal' : 'card'"
            height="200px"
            :arrow="isMobile ? 'always' : 'hover'"
          >
            <el-carousel-item v-for="(item, index) in videoList" :key="index">
              <video-viewer
                width="100%"
                height="200px"
                :title="item.title"
                :cover="item.cover"
                :url="item.url"
              ></video-viewer>
            </el-carousel-item>
          </el-carousel>
        </div>
      </el-card>
    </div>

    <!-- 资质 -->
    <div class="module-wrap honour">
      <div class="module-container">
        <div class="module-title">资质荣誉</div>
        <div class="module-body">
          <el-card
            class="img-card"
            @click.native="showPic(item.url)"
            v-for="(item, index) in honorList"
            :key="index"
            :body-style="{ padding: '0px' }"
            shadow="hover"
          >
            <el-image :src="item.url" fit="cover"></el-image>
            <div class="desc">{{ item.desc }}</div>
          </el-card>
        </div>
      </div>
    </div>

    <!-- 合作伙伴 -->
    <div class="module-wrap cooperation">
      <div class="module-container">
        <div class="module-title">合作客户</div>

        <div class="module-body">
          <div
            v-for="(item, index) in cooperationList"
            :key="index"
            @click="showPic(item)"
            class="cooperation-item"
          >
            <el-image v-if="item" :src="item" fit="contain"></el-image>
          </div>
        </div>
      </div>
    </div>

    <transition name="el-fade-in-linear">
      <el-image-viewer
        v-if="preview"
        :url-list="[currentPic]"
        :on-close="
          () => {
            preview = false;
          }
        "
      />
    </transition>
  </div>
</template>

<script>
import VideoViewer from '@/components/VideoViewer';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';

export default {
  data() {
    return {
      preview: false,
      currentPic: '',
      backgoundImg: require('@/assets/images/IMG_3613.jpg'),
      videoList: [
        {
          title: '公司介绍',
          cover: require('@/assets/images/video_cover1.png'),
          url: 'http://39.99.237.105/resource/第一页公司介绍/公司介绍视频.mp4',
        },
        {
          title: '可美口罩工厂',
          cover: require('@/assets/images/video_cover2.png'),
          url:
            'http://39.99.237.105/resource/第一页公司介绍/可美口罩工厂视频.mp4',
        },
        {
          title: '可美品牌大厦',
          cover: require('@/assets/images/video_cover3.png'),
          url:
            'http://39.99.237.105/resource/第一页公司介绍/可美品牌大厦视频.mp4',
        },
      ],
      honorList: [
        {
          desc: '成人国标检测GBT32610',
          url:
            'http://39.99.237.105/resource/第一页公司介绍/资质荣誉/成人国标检测GBT32610-2016 (2).png',
        },
        {
          desc: '儿童国标检测GBT38880-2020',
          url:
            'http://39.99.237.105/resource/第一页公司介绍/资质荣誉/儿童国标检测GBT38880-2020.png',
        },
        {
          desc: '外观设计专利证书',
          url:
            'http://39.99.237.105/resource/第一页公司介绍/资质荣誉/外观设计专利证书.png',
        },
        {
          desc: 'FDA认证',
          url:
            'http://39.99.237.105/resource/第一页公司介绍/资质荣誉/FDA认证.png',
        },
        {
          desc: 'KN95国标检测GB2626-2006',
          url:
            'http://39.99.237.105/resource/第一页公司介绍/资质荣誉/KN95国标检测GB2626-2006.png',
        },
      ],
      cooperationList: [
        'http://39.99.237.105/resource/第一页公司介绍/合作客户/北大.jpeg',
        'http://39.99.237.105/resource/第一页公司介绍/合作客户/清华.jpg',
        'http://39.99.237.105/resource/第一页公司介绍/合作客户/复旦.jpg',
        'http://39.99.237.105/resource/第一页公司介绍/合作客户/上海交大.jpg',
        'http://39.99.237.105/resource/第一页公司介绍/合作客户/南农.jpg',

        'http://39.99.237.105/resource/第一页公司介绍/合作客户/金科.jpeg',

        'http://39.99.237.105/resource/第一页公司介绍/合作客户/中国人寿.jpg',
        'http://39.99.237.105/resource/第一页公司介绍/合作客户/友邦.jpg',
        'http://39.99.237.105/resource/第一页公司介绍/合作客户/中海.jpg',
        'http://39.99.237.105/resource/第一页公司介绍/合作客户/中石油.jpg',
        'http://39.99.237.105/resource/第一页公司介绍/合作客户/中远洋.jpg',
        'http://39.99.237.105/resource/第一页公司介绍/合作客户/华润.jpg',

        'http://39.99.237.105/resource/第一页公司介绍/合作客户/沃尔沃.jpg',
        'http://39.99.237.105/resource/第一页公司介绍/合作客户/汉堡王.jpg',
        'http://39.99.237.105/resource/第一页公司介绍/合作客户/格力高.jpg',
        'http://39.99.237.105/resource/第一页公司介绍/合作客户/美国路易斯安那大学拉法叶分校.jpg',

        'http://39.99.237.105/resource/第一页公司介绍/合作客户/ky-ph.jpg',
        'http://39.99.237.105/resource/第一页公司介绍/合作客户/andaz.jpg',
        'http://39.99.237.105/resource/第一页公司介绍/合作客户/ky-gh.jpg',
        'http://39.99.237.105/resource/第一页公司介绍/合作客户/ky-hyatt.jpg',
      ],
    };
  },
  components: {
    VideoViewer,
    ElImageViewer,
  },
  computed: {
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
  methods: {
    showPic(url) {
      this.preview = true;
      this.currentPic = url;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-image-viewer__wrapper .el-icon-circle-close {
  color: #fff !important;
}

.about-wrap {
  min-height: calc(100vh - 80px);

  .head-wrap {
    position: relative;
    height: 72vh;
    min-height: 500px;

    .background-wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      bottom: 90px;
      background-repeat: repeat;
      background-size: 300px;
      overflow: hidden;
    }

    .mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      bottom: 90px;
      background-color: rgba(0, 0, 0, 0.5);
    }

    .com-info {
      position: absolute;
      bottom: 0;
      width: 900px;
      left: 50%;
      transform: translateX(-50%);

      .intro-title {
        .title-24;
        text-align: center;
      }

      .intro {
        line-height: 1.5;
        font-size: 16px;
        color: #909399;
        text-indent: 32px;
      }
    }
  }

  .module-wrap {
    background-color: #fff;
    .module-container {
      padding-bottom: 40px;

      .module-title {
        background-image: linear-gradient(to bottom, #f7f7f7, #fff);

        .title-24;
        font-size: 30px;
        padding: 30px 0;
        text-align: center;
      }

      .module-body {
        max-width: @mainContainerWidth;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 0;

        .img-card {
          width: calc(20% - 20px);
          &:hover {
            cursor: zoom-in;
          }

          .el-image {
            width: 100%;
            height: 280px;
          }
        }

        .desc {
          padding: 10px;
          font-size: 14px;
          text-align: center;
          border-top: 1px solid #eee;
        }
      }
    }
  }

  .cooperation {
    .module-body {
      flex-wrap: wrap;
      justify-content: flex-start !important;

      .cooperation-item {
        width: 20%;
        height: 120px;
        .all-center;

        .el-image {
          width: 40%;
          margin: 0 auto;
          &:hover {
            cursor: zoom-in;
          }
        }
      }
    }
  }
}

// 移动端
@media screen and (max-width: 768px) {
  .intro-title,
  .module-title {
    font-size: 18px !important;
  }

  .head-wrap {
    height: 85vh !important;

    .com-info {
      width: 100vw !important;
    }
  }

  .module-body {
    .img-card {
      width: calc(100vw - 40px) !important;

      & + .img-card {
        margin-top: 20px !important;
      }

      .el-image {
        width: 100%;
        height: calc(100vw - 40px) !important;
      }

      .desc {
        font-size: 16px !important;
      }
    }
  }

  .honour {
    .module-body {
      flex-wrap: wrap;
      padding: 0 20px;
      .img-card {
        width: calc(50vw - 30px) !important;
        .el-image {
          width: 100%;
          height: calc(50vw - 40px) !important;
        }
      }
    }
  }

  .cooperation {
    .module-body {
      .cooperation-item {
        width: 33.33% !important;
      }
    }
  }
}
</style>
